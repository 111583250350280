import React from 'react'


import Contact from '../contact/ContactForm'
import MenusSA from '../menusSA/MenusSA';


const MHSection = () => {
  return (
    <>
    <MenusSA />
    <Contact />
    </>
  )
}

export default MHSection
